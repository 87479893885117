import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { lazy, Suspense } from 'react';
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/animate.css'
import './assets/css/slick.css';
import './assets/css/off-canvas.css';
import './assets/fonts/linea-fonts.css';
import './assets/fonts/flaticon.css';
import './assets/css/magnific-popup.css';
import './assets/css/rs-spacing.css';


import AdminRoutes from './Component/Admin/AdminRoutes';
import checkAuth from './Component/Authentication/Auth';
import Loding from './Loding';
const Header = lazy(() => import('./Component/Header'));
const Home = lazy(() => import('./Component/Home'));
const Footer = lazy(() => import('./Component/Footer'));
const FullStackfundation = lazy(() => import('./Component/Program/FullStackfundation'));
const BackendDevelopment = lazy(() => import('./Component/Program/BackendDevelopment'));
const FullStackDevelopment = lazy(() => import('./Component/Program/FullStackProgram'));
const FrontendDevelopment = lazy(() => import('./Component/Program/FrontendDevelopment'));
const UianduxDevelopment = lazy(() => import('./Component/Program/UiDevelopment'));
const DigitalMarketing = lazy(() => import('./Component/Program/DigitalMarketing'));
const BCAProgram = lazy(() => import('./Component/Program/BCAProgram'));
const MCAProgram = lazy(() => import('./Component/Program/MCAProgram'));
const Club = lazy(() => import('./Component/Club'));
const Blog = lazy(() => import('./Component/Blog'));
const Team = lazy(() => import('./Component/Team'));
const AboutUS = lazy(() => import('./Component/AboutUs'));
const Contact = lazy(() => import('./Component/Contact'));
const FAQ = lazy(() => import('./Component/FAQ'));
const PrivacyAndPolicy = lazy(() => import('./Component/Privacy/PrivacyAndPolicy'));
const TermsAndCondition = lazy(() => import('./Component/Privacy/TermsAndCondition'));
//blogs
const FullStackwithai = lazy(() => import('./Component/Blogs/FullStackwithai'));
const KeyCommunication = lazy(() => import('./Component/Blogs/KeyCommunication'));
const TopThreeSkill = lazy(() => import('./Component/Blogs/TopThreeSkill'));
const TechnicalSeo = lazy(() => import('./Component/Blogs/TechnicalSeo'));
const Digitalmarketingwithai = lazy(() => import('./Component/Blogs/DigitalMarketWithai'));
const LearnEarlyAndEarntEarly = lazy(() => import('./Component/Blogs/LearnEarly'));
const CareerAfter12th = lazy(() => import('./Component/Blogs/CareerAfter12th'));
const TheSeoOptimization = lazy(() => import('./Component/Blogs/TheSeoOptimization'));
const CareerOptions = lazy(() => import('./Component/Blogs/CareerOption'));
//Users
const Login = lazy(() => import('./Component/Users/Login'));
const StudentText = lazy(() => import('./Component/Users/StudentText'));
const VerifyCertificate = lazy(() => import('./Component/Users/VerifyCertificate'));
const AdminLogin = lazy(() => import('./Component/Admin/AdminLogin'));

function App() {
  const token=checkAuth();
  return (
    <BrowserRouter>
      <Suspense fallback={<Loding/>}>
      <Routes>
        <Route path='/' element={<><Header /><Home /><Footer /></>} />
        {/* our Programs */}
        <Route path='/foundation-training-program-in-agra' element={<><Header /><FullStackfundation /><Footer /></>} />
        <Route path='/back-end-web-developer-training-in-agra' element={<><Header /><BackendDevelopment /><Footer /></>} />
        <Route path='/full-stack-web-development-training-in-agra' element={<><Header /><FullStackDevelopment /><Footer /></>} />
        <Route path='/front-end-developer-training-in-agra' element={<><Header /><FrontendDevelopment /><Footer /></>} />
        <Route path='/graphic-designing-training-program-in-agra' element={<><Header /><UianduxDevelopment /><Footer /></>} />
        <Route path='/digital-marketing-training-in-agra' element={<><Header /><DigitalMarketing /><Footer /></>} />
        <Route path='/bca-training-program-in-agra' element={<><Header /><BCAProgram /><Footer /></>} />
        <Route path='/mca-training-program-in-agra' element={<><Header /><MCAProgram /><Footer /></>} />

        <Route path='/Club' element={<><Header /><Club /><Footer /></>} />
        <Route path='/blogs' element={<><Header /><Blog /><Footer /></>} />
        <Route path='/teams' element={<><Header /><Team /><Footer /></>} />
        <Route path='/about' element={<><Header /><AboutUS /><Footer /></>} />
        <Route path='/contact' element={<><Header /><Contact /><Footer /></>} />

        <Route path='/FAQ' element={<><Header /><FAQ /><Footer /></>} />
        <Route path='/privacypolicy' element={<><Header /><PrivacyAndPolicy /><Footer /></>} />
        <Route path='/termsandcondition' element={<><Header /><TermsAndCondition /><Footer /></>} />

        {/* Blog */}
        <Route path='/Full-Stack-with-Ai' element={<><Header /><FullStackwithai /><Footer /></>} />
        <Route path='/key-communication-skill' element={<><Header /><KeyCommunication /><Footer /></>} />
        <Route path='/top-three-skills' element={<><Header /><TopThreeSkill /><Footer /></>} />
        <Route path='/technical-seo' element={<><Header /><TechnicalSeo /><Footer /></>} />
        <Route path='/ai-in-digital-marketing' element={<><Header /><Digitalmarketingwithai /><Footer /></>} />
        <Route path='/learn-early-and-earn-early-after-12th' element={<><Header /><LearnEarlyAndEarntEarly /><Footer /></>} />
        <Route path='/career-after-12th' element={<><Header /><CareerAfter12th /><Footer /></>} />
        <Route path='/The-Role-of-On-Page-SEO' element={<><Header /><TheSeoOptimization /><Footer /></>} />
        <Route path='/career-option' element={<><Header /><CareerOptions /><Footer /></>} />

        <Route path='/login' element={<><Header /><Login /><Footer /></>} />
        <Route path='/student-test' element={<StudentText />} />
        <Route path='/verify' element={<><Header /><VerifyCertificate /><Footer /></>} />

        {/* Admin Path */}
        <Route path='/admin/login' element={<AdminLogin />} />
       
        <Route path="/app/*" element={<AdminRoutes />} />
        {/* <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/admin/login"} replace />} /> */}
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
